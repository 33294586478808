import React from 'react'
import Text from './core/Text'

const mapLevelToSize = {
  1: [2, 1],
  2: [4, 5],
  3: [3, 4],
  4: [2, 3],
  5: [2, 3],
}

const Heading = ({ children, as = 'h1', level, isFlush, ...props }) => (
  <Text
    as={as}
    mt={0}
    mb={0}
    color="black"
    fontFamily={level === 2 ? 'serif' : 'sans'}
    fontSize={mapLevelToSize[level]}
    fontWeight={level === 3 || level === 2 || level === 5 ? 'bold' : 'normal'}
    {...props}
  >
    {children}
  </Text>
)

export default Heading

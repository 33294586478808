import React from 'react'
import styled from '@emotion/styled'
import { border } from 'styled-system'
import Image from 'gatsby-image'
import { Box } from 'reflexbox'

const Outer = styled(Box)`
  ${border}
  transform-style: preserve-3d;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 5px;
    top: 5px;
    border: 1px solid rgba(0, 0, 0, 0.13);
    z-index: -1;
    transform: translateZ(-1px);
  }
`

const ProductImage = ({ fluid, alt, sizes, ...props }) => (
  <Outer border="1px solid" borderColor="keyline" mb={3} {...props}>
    <Image
      fluid={{
        ...fluid,
        sizes,
      }}
      alt={alt}
    />
  </Outer>
)

export default ProductImage

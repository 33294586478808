import React from 'react'
import styled from '@emotion/styled'
import Link from './core/Link'

const Wrapper = styled(Link)`
  text-decoration: none;
  position: relative;
  transform-style: preserve-3d;
  cursor: pointer;

  &:disabled {
    cursor: default;
    background: transparent !important;
  }

  &:focus {
    outline: none;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 5px;
    top: 5px;
    border: 1px solid rgba(0, 0, 0, 0.13);
    z-index: -1;
    transform: translateZ(-1px);
  }

  &:hover {
    background-color: #efeee6;
  }
`

const Button = ({ children, onClick, ...props }) => (
  <Wrapper
    display="block"
    bg="highlight"
    border="1px solid"
    borderColor="keyline"
    color="black"
    py={3}
    px={4}
    width="100%"
    fontSize={[3, 4]}
    fontFamily="sans"
    textAlign="center"
    as={onClick && 'button'}
    onClick={onClick}
    {...props}
  >
    {children}
  </Wrapper>
)

export default Button
